
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import AcademyRegularClassTeaser from '@/modules/academy/components/AcademyRegularClassTeaser/AcademyRegularClassTeaser.vue';

export default defineComponent({
  components: {
    Button,
    AcademyRegularClassTeaser,
  },
  setup() {
    const GAME_URL =
      'https://philip-morris-germany.leadfamly.com/prio-puzzle-pattern-for-m';
    const headerImages = {
      lg: require('@/assets/images/navigation/togetherday-lg.jpeg'),
      sm: require('@/assets/images/navigation/togetherday-sm.jpeg'),
    };

    const store = useStore();

    const userId = store.getters['authModule/userId'];
    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];
    const isDesktop = ref(false);

    return {
      token,
      userId,
      isDesktop,
      headerImages,
      gameURL: GAME_URL,
      BUTTON_THEMES,
    };
  },
});
